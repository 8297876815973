import React from 'react'
import { graphql } from 'gatsby'
import ReactMarkdown from 'react-markdown'
import Layout from '../components/layout'
import InsideHeader from '../components/insideheader'
import Team from '../components/team'

export default ({ data }) => {
  console.log(data)
  const page = data.dataJson
  return (
    <Layout>
      <InsideHeader
        pageTitle={page.title}
        pageSubTitle={page.subTitle}
        pageSlug={page.slug}
        headerImage={page.titleBackground}
      />
      <main className="main-content">
        <section className="section">
            <div className="row">
              <div className="col-md-10 mx-auto">
                {page.contents.map(item => (
                  <div className="row gap-y justify-content-center">
                    <div className="row col-12 justify-content-center">
                        <ReactMarkdown source={item.heading} escapeHtml={false} />
                    </div>
                    {item.profiles.map(profile => (
                      <Team member={profile} />
                    ))}
                    <div className="row col-12 justify-content-center"><br /></div>
                  </div>
                ))}
              </div>
          </div>
        </section>
      </main>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    dataJson(fields: { slug: { eq: $slug } }) {
      title
      subTitle
      location
      titleBackground
      contents {
        heading
        image
        content
        profiles {
          name
          title
          location
          image
          link
        }
      }
    }
  }
`
