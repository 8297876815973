import React from 'react'
import PropTypes from 'prop-types'
import {Link} from 'gatsby'

const Team = ({ member }) => (
    <div className="col-md-6 col-lg-3 rounded-lg team-1 border m-2 shadow-2 hover-shadow-9 bg-white">
        <Link to={member.link}>
          <img src={member.image} alt={member.name} className="img-fluid rounded-lg" style={{height: "250px"}} />
        <h6>{member.name}</h6>
        <small>{member.title}<br/>{member.location}</small>
        {/*<div className="social social-gray">
          <a className="social-twitter" href="#">
            <i className="fa fa-twitter" />
          </a>
          <a className="social-facebook" href="#">
            <i className="fa fa-facebook" />
          </a>
          <a className="social-instagram" href="#">
            <i className="fa fa-instagram" />
          </a>
        </div>*/}
        </Link>
    </div>
)

Team.propTypes = {
  member: PropTypes.object,
}

export default Team
